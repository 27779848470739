<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  //   BellIcon,
  //   BellOffIcon,
} from "vue-feather-icons";
// import { Slide } from "vue-carousel";
// import { Carousel } from "vue-carousel";
// import { VueTyper } from "vue-typer";
import Multiselect from "vue-multiselect";
import IndexService from "@/MainServices/IndexService.js";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import GeneralService from "@/MainServices/GeneralService.js";
// import Skeleton from "@/view/front/components/skeleton";

/**
 * page-index-home component
 */
export default {
  data() {
    return {
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      token:
        localStorage.getItem("token") !== null
          ? `Bearer ${localStorage.getItem("token")}`
          : null,
      data: [],
      auth: localStorage.getItem("id_token") !== null ? true : false,
      search_text: "",
      selected_cities: [],
      city: "",
      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,
      //   sectorsData: [],
      //   featureData: [],
      //   spotlightData: [],
      //   urgentData:[],
      //   featureCompaniesData: [],
      //   descriptionSearch: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    // Carousel,
    // Slide,
    // FacebookIcon,
    // InstagramIcon,
    // TwitterIcon,
    // LinkedinIcon,
    // ArrowUpIcon,
    // "vue-typer": VueTyper,
    Multiselect,
    // Skeleton,
    // BellIcon,
    // BellOffIcon,
  },
  mounted() {
    IndexService.getCities().then((res) => {
      res.data.map((el) => {
        this.cities.push({
          id: el.id,
          value: el.city_name,
        });
      });
      // console.log( this.cities)
      setTimeout(() => {
        document.querySelector(".temp1").textContent =
          document.querySelector(".temp").textContent.slice(0, 100) + "...";
        document.querySelector(".temp2").textContent =
          document.querySelector(".temp3").textContent.slice(0, 100) + "...";
      }, 1000);
    });
    // this.getSectors();
    // this.getFeatureJobs();
    // this.getSpotlightJobs();
    // this.getFeatureCompanies();
    // this.getUrgentJobs();
  },
  methods: {
    _descriptionSearch(event) {
      this.descriptionSearch = event.target.checked;
    },
    search() {
      this.$router.push({
        name: "search",
        params: {
          search_text: this.search_text,
          selected_cities: this.selected_cities,
          description_search: this.descriptionSearch,
        },
      });
    },
    getSectors() {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.sectorsData = response.data;
        this.loading = false;
      });
    },
    getFeatureJobs() {
      axios.get(`${this.apiUrl}/get-feature-jobs`).then((response) => {
        this.featureData = response.data;
      });
    },
    getSpotlightJobs() {
      axios.get(`${this.apiUrl}/get-spotlight-jobs`).then((response) => {
        this.spotlightData = response.data;
      });
    },
    getUrgentJobs() {
      axios.get(`${this.apiUrl}/get-urgent-jobs`).then((response) => {
        this.urgentData = response.data;
      });
    },
    getFeatureCompanies() {
      if (this.token !== null) {
        axios.defaults.headers.common["Authorization"] = this.token;
        axios
          .get(`${this.apiUrl}/get-feature-companies-auth`)
          .then((response) => {
            this.featureCompaniesData = response.data;
          });
      } else {
        axios.get(`${this.apiUrl}/get-feature-companies`).then((response) => {
          this.featureCompaniesData = response.data;
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-white d-table w-100">
      <!-- <div class="container"> -->
      <div
        class="row intro-header img_about_us_mb"
        style="
          background: url(/images/job_in_pk/Mainbg.png) no-repeat center center;
          background-size: cover;
          min-height: 70vh;
        "
      ></div>
      <!-- Top Form -->
      <div
        class="row container form-section"
        style="padding-top: 5%; margin: 0 auto"
      >
        <div
          class="top-form about_job4u_mb"
          style="background-color: #2eca8b; margin-top: -177px"
        >
          <div class="col-sm-12 col-md-12 col-lg-12 about_job4u_neg_margin_mb">
            <form action="job-listing.html">
              <div class="row">
                <div
                  class="
                    col-md-5
                    form-group
                    m-0
                    pl-0
                    r_p_z
                    b_p_imb
                    position__ch
                  "
                >
                  <!-- <label class="labelch7">WHAT</label> -->
                  <input
                    type="text"
                    v-model="search_text"
                    @keyup.enter="search()"
                    class="form-control fieldch_new selectch fieldch_new_imb"
                    placeholder="type your dream job"
                  />
                  <img
                    src="images/s_3.svg"
                    width="15"
                    height="15"
                    alt="..."
                    class="icon_search__"
                  />
                </div>

                <div class="col-md-5 form-group m-0 p-0 position__ch">
                  <!-- <label class="labelch7">WHERE</label> -->
                  <multiselect
                    class="
                      fieldch_new
                      selectch
                      home_city_selct
                      multiselect__tags_imb
                    "
                    id="multiselectCities"
                    v-model="selected_cities"
                    :height="300"
                    :options="cities"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="location you love"
                    label="value"
                    track-by="value"
                    :preselect-first="false"
                  >
                  </multiselect>
                  <img
                    src="images/s_4.svg"
                    width="15"
                    height="15"
                    alt="..."
                    class="icon_search__"
                  />
                </div>

                <div class="col-md-6 form-group mb-0 mt-2 display_img">
                  <div class="checkboxch chabox_ch_home">
                    <small
                      ><label class="text-dark pl-8"
                        ><input
                          type="checkbox"
                          value="Yes"
                          style="margin-left: -25px"
                          class="form-check-input"
                          v-on:click="_descriptionSearch($event)"
                        />
                        Search job title only</label
                      >
                    </small>
                  </div>
                </div>

                <div class="col-md-2 form-group m-0 pr-0">
                  <!-- <div class="pull-right"> -->
                  <button
                    @click="search()"
                    type="button"
                    class="btn btn-default btn_red_redus"
                    style="background-color: #005696 !important"
                  >
                    Go Find
                  </button>
                  <!-- </div> -->
                </div>

                <div class="col-md-6 form-group mb-0 mt-2 hide_img">
                  <div class="checkboxch chabox_ch_home">
                    <label class="text-white pl-8"
                      ><input
                        type="checkbox"
                        value="Yes"
                        style="margin-left: -25px"
                        class="form-check-input"
                        v-on:click="_descriptionSearch($event)"
                      />
                      Search job title only</label
                    >
                  </div>
                </div>

                <div class="col-sm-12 form-group m-0">
                  <p class="mt-2 mb-0 text-white">
                    <b class="text-white">Quick searches:</b>&nbsp;&nbsp;<a
                      class="ch_link_c text-white"
                      href="/job-sectors"
                      >Jobs By Sectors</a
                    >
                    |
                    <a class="ch_link_c text-white" href="/jobs-in-pakistan"
                      >Graduate jobs</a
                    >
                    |
                    <a class="ch_link_c text-white" href="#">Internships</a>
                    |
                    <a class="ch_link_c text-white" href="/jobs-in-pakistan"
                      >Urgent Jobs</a
                    >
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="pk_bg_img mt-lg-20 t_m_z pk_bg_img_imb">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-6 pl-0">
            <h2 class="h2_job_in_pk_imb">
              Join <span>job4u.pk</span> to hire top talent & find great jobs in
              Pakistan
            </h2>
            <div class="pr-lg-40 r_p_z" style="font-size: 13px">
              Sign up today and start using the top jobs website to find our
              dream jobs in Pakistan.
            </div>
            <div class="hr_green"></div>
            <ul class="list_style_imb l_r_p_z">
              <li>
                <span class="about_heading3">Customise your search </span
                ><span class="p_about"
                  >by browsing thousands of jobs in Pakistan and narrowing down
                  your search</span
                >
              </li>
              <li>
                <span class="about_heading3"
                  >Create job alerts for new job postings </span
                ><span class="p_about">and get free email notifications</span>
              </li>
              <li>
                <span class="about_heading3"
                  >Prepare for applications and interviews </span
                ><span class="p_about"
                  >with job4u.pk career resources sections</span
                >
              </li>
            </ul>
            <a href="/recruiter-registeration" class="join_btn2"> JOIN NOW </a>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
      <div class="text-center bg-light py-20">
        <h2 class="pk_heading_scend pk_heading_scend_imb">Looking for more?</h2>
        <div class="sub_heading_imb" style="color: #349fe6; font-size: 16px">
          <img
            class="pr-3"
            height="20px"
            src="/images/job_in_pk/readbelow.png"
            alt=""
          />Read below for top job articles
        </div>
      </div>
      <div class="bg_img_greeen">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-6 pl-0">
            <h2 class="pk_heading_therd">Search for jobs in Pakistan</h2>
            <p>
              Job4u.pk is the number one hiring website to find jobs in
              Pakistan. As the best Pakistan careers website, Job4u offers a job
              search tool that allows you to browse through thousands of jobs
              across Pakistan and to apply filters that specify your needs. A
              better career is out there and Job4u.pk is here to help you find
              it.
            </p>
            <a href="/jobs-in-pakistan" class="join_btn2">SEARCH JOBS NOW</a>
          </div>
          <div class="col-lg-6">
            <img
              class="pk_job_img"
              src="/images/job_in_pk/searchjobs.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="bg-light about_p_by_ch">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-4 pl-0 bool_section_ch">
            <img
              class="pk_typrighter"
              src="/images/job_in_pk/typewritter.png"
              alt=""
            />
          </div>
          <div class="col-lg-7 align-baseline pt-37">
            <h2 class="text-muted pk_heading_therd">
              Build a good resume to win a dream job
            </h2>
            <p class="text-muted">
              An efficient resume is one of the most important aspect of a
              successful job application. A good resume should showcase a
              candidate's potential and catch recruiters attention. Job4u.pk,
              best Pakistan jobs website, provide you with resources on how to
              build an effective resume.
            </p>
            <a
              v-if="!auth"
              href="/login"
              class="join_btn2"
              style="background: #2eca8b"
            >
              LEARN MORE
            </a>
            <a
              v-if="auth"
              href="/dashboard"
              class="join_btn2"
              style="background: #2eca8b"
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
      <div class="pk_img_bg_dark bg_img_greeen">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-6 pl-0">
            <h2 class="pk_heading_therd text-white">
              Search for jobs in Pakistan
            </h2>
            <p class="text-white">
              Job4u.pk is the number one hiring website to find jobs in
              Pakistan. As the best Pakistan careers website, Job4u offers a job
              search tool that allows you to browse through thousands of jobs
              across Pakistan and to apply filters that specify your needs. A
              better career is out there and Job4u.pk is here to help you find
              it.
            </p>
            <a href="/jobs-in-pakistan" class="join_btn2">SEARCH JOBS NOW</a>
          </div>
          <div class="col-lg-6">
            <!-- <img class="pk_job_img" src="/images/job_in_pk/searchjobs.png" alt=""> -->
          </div>
        </div>
      </div>
      <div class="bg-light py-20">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-4 pl-0 text-end">
            <img
              class="pk_dekstop"
              src="/images/job_in_pk/recruittoptalent.png"
              alt=""
            />
          </div>
          <div class="col-lg-7 align-baseline pt-8">
            <h2 class="text-muted pk_heading_therd">
              Recruit top talents in Pakistan
            </h2>
            <p class="text-muted">
              Get great applicants and recruit the top talent in Pakistan with
              Job4u.pk. Potential candidates come to top jobs website Job4u.pk
              every day to discover opportunities and build their careers. We
              make sure to put your job in front of the most qualified
              candidates. Additionally, to help you find the right person for
              your job, insights from our community will match your job post to
              people with similar skills, experience, and goals.
            </p>
            <!-- <button class="join_btn2" style="background: #2eca8b;">LEARN MORE</button> -->
          </div>
        </div>
      </div>
      <div class="bg-white py-10">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-12 align-baseline pt-8 px-0">
            <h2
              class="pk_heading_therd text-center pb-5 mb-5"
              style="
                border-bottom: 2px solid black;
                font-size: 40px;
                color: #106409;
              "
            >
              Browse Local Jobs
            </h2>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Lahore" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Lahore.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Faislabad" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Faislabad.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Gujranwala" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Gujranwala.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Islamabad" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Islamabad.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Peshawar" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Peshawar.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Quetta" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Quetta.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Sialkot" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Sialkot.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
                <div class="col-lg-3 col-6 mb-8">
                  <router-link to="/jobs/Pakistan/Karachi" class="">
                    <img
                      class="media-object w-100"
                      src="images/home/city/Karachi.jpeg"
                      width=""
                      height=""
                      alt="..."
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

  <style scoped>
body {
  font-size: 15px !important;
}
.text-muted {
  color: black !important;
}
.settings {
  display: none !important;
}
._bold {
  font-weight: bold;
}
.pix12 {
  font-size: 12px;
}
.vjs_video_3-dimensions {
  width: 602px !important;
  height: 376px !important;
}

.video-js[tabindex="-1"] {
  outline: none;
  /* width: 177%; */
}

@media (min-width: 1024px) {
  .job-profile {
    top: 0px !important;
  }
}

.job_city_title:link {
  text-decoration: none !important;
}
.job_city_title:visited {
  text-decoration: none !important;
}
.job_city_title:hover {
  text-decoration: none !important;
}
.job_city_title:hover {
  color: white !important;
}
.job_city_title:active {
  text-decoration: none !important;
}
.bold {
  font-weight: bold !important;
}
.filter_heading {
  font-weight: bold;
  font-size: 13px;
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.detail_heading {
  font-weight: bold !important;
  font-size: 13px !important;
}
.detail_body {
  font-size: 13px !important;
}
.multiselect__placeholder {
  font-size: 13px !important;
}
.multiselect__option {
  font-size: 13px !important;
}
.multiselect__tag {
  font-size: 10px !important;
}
.job-box:hover {
  -webkit-transform: none !important;
  transform: none !important;
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 14px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 1;
}
.multiselect--active .multiselect__current,
.multiselect--active .multiselect__input,
.multiselect--active .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 1px 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 6px;
  margin-bottom: 8px;
}
.multiselect__tags {
  min-height: 46px !important;
  padding: 10px 40px 0 8px !important;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 8px;
  white-space: nowrap;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content {
  position: absolute;
  list-style: none;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
}
.multiselect--above .multiselect__content {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  cursor: text;
  pointer-events: none;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
@keyframes a {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
</style>